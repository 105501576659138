















































































































































































































































































































































































































































































































































import Vue from "vue";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";

export default Vue.extend ({
    components: {
        PageTitleHeader
    },
    methods: {
        backgroundImage() {
            const img = `/bg/awards-bg.jpg`;
            if (img) {
                return {
                    "background-image": `url(${img})`
                };
            } else {
                return {};
            }
        }
    }
})
